import { Link } from 'react-router-dom'
import mesh from '../css/mesh.module.css'
import header from './header.module.css'
import button from '../css/commonButtons.module.css'

const getHours = (start, end) => {
    const now = new Date()
    const differenceByMsk = (now.getTimezoneOffset()/60)+3
    let [startWork, endWork] = [start, end]
    startWork = now.getHours(now.setHours(startWork-differenceByMsk))
    endWork = now.getHours(now.setHours(endWork-differenceByMsk))
    return `${startWork.length<2 ? '0'+startWork:startWork}:00-
        ${endWork.length<2 ? '0'+endWork:endWork}:00`
}

const Header = ({openModals, setOpenModals, color, logoPaths}) => {
    const workHours = getHours(6, 15)
    return (
        <header className={`${mesh.container} ${color}`}>
            <nav>
                <ul>
                    <li className={header.logo}>
                        <Link to="/">
                            <img className={header.firmNameLogo} alt="Логотип" src={`images/${logoPaths.fermaLogo}`} />
                            <img className={header.agencyLogo} alt="Рекламное агенство" src={`images/${logoPaths.agencyLogo}`} />
                        </Link>
                    </li>
                    <li className={header.phone}>
                        <div className={header.numberPhone}><p>+7 (3812) </p><p>308-136</p></div>
                        <p className={header.requestPhone}>ПН-ПТ <br />
                            {workHours}</p>
                    </li>
                    <li>
                        <button 
                            className={`${header.callButton} ${button.purpleButton}`}
                            id="callButton"
                            // Active feedbackModal
                            onClick={() => setOpenModals({...openModals, form: true})}
                        >Позвоним бесплатно</button>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header